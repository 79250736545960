import cn from 'classnames';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { getExternalUrl, isSetToAllRoutesExternal, isSupportedRoute } from '@gemini/shared/services/utils/global';
import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import { useRouter } from 'next/router';

export interface IProductDescriptionProps {
  className?: string;
  text?: string;
  url?: string;
}

export function ProductDescription(props: IProductDescriptionProps) {
  const { className, text, url } = props;
  const { allowedPaths } = useAppContext();
  const router = useRouter();

  if (!text) {
    return null;
  }

  if (text.includes('<A ') || text.includes('<a ')) {
    return (
      <div
        className={cn('cursor-pointer', className)}
        dangerouslySetInnerHTML={{
          __html: text
        }}
        onClick={(event) => {
          if ((event.target as HTMLElement).tagName.toLowerCase() === 'a') {
            return;
          }
          event.preventDefault();
          let link = url || '';

          if (
            !isSupportedRoute(link, allowedPaths) ||
            isSetToAllRoutesExternal()
          ) {
            link = getExternalUrl(link);
            window.location.assign(link);
          } else {
            router.push(link);
          }
        }}
        data-href={url}
        data-role="link"
      />
    );
  }

  return (
    <div className={cn(className)}>
      <SmartLink
        href={url ? url : ''}
        dangerouslySetInnerHTML={{
          __html: `${text}`
        }}
      />
    </div>
  );
}

export default ProductDescription;
