import React, { useState } from 'react';
import { IImage, SkuType } from '@gemini/shared/services/products/catalog';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

interface IProductBriefImage {
  sku: SkuType;
  badge?: string;
  priority?: boolean;
  size?: Size;
}

export const ProductBriefImage = ({
  badge,
  sku,
  size = Size.medium,
  priority = false
}: IProductBriefImage) => {
  const [showAlt, setShowAlt] = useState(false);
  const skuMedia = sku.media[size];
  const primaryImage = skuMedia ? skuMedia[0] : ({} as IImage);
  const altImage = skuMedia ? skuMedia[1] : ({} as IImage);

  const onMouseLeave = () => setShowAlt(false);
  const onMouseEnter = () => setShowAlt(true);

  const showAltImage = altImage && showAlt;
  const imageProp = showAltImage ? altImage : primaryImage;

  return (
    <div
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      className="cursor-pointer"
    >
      <SmartImage
        src={imageProp.src || ''}
        height={imageProp.height}
        width={imageProp.width}
        alt={sku.skuId}
        loading={priority ? undefined : 'lazy'}
        priority={!showAltImage && priority}
      />
      {badge ? (
        <div className="absolute font-bold uppercase top-2 left-2 whitespace-nowrap">
          {badge}
        </div>
      ) : null}
    </div>
  );
};

export default ProductBriefImage;
