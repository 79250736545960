import { useMemo, useState } from 'react';
import {
  Price,
  PriceTax,
  ProductType,
  SkuType
} from '@gemini/shared/services/products/catalog';

export interface IProductPriceProps {
  classes?: string;
  prices?: Price | null;
  product?: ProductType;
  hidePriceRange?: boolean;
  showPricePerUnit?: boolean;
}

const DiscountedPrice = ({ includeTax }: Price) => {
  return (
    <div className="flex leading-[1.25rem] product-price font-akzidenzgrotesk text-15px">
      <div className="font-bold line-through original-price">
        {includeTax.originalPriceFormatted}
      </div>
      <div className="font-bold text-red-500 md:tracking-[1px] discounted-price">
        {includeTax.priceFormatted}
      </div>
    </div>
  );
};

const PriceRange = (rangeOfPrices: PriceTax[]) => {
  return (
    <div className="font-bold leading-[1.25rem] product-price font-akzidenzgrotesk text-15px text-navy">
      {`${rangeOfPrices[0].priceFormatted} - ${rangeOfPrices[1].priceFormatted}`}
    </div>
  );
};

const OriginalPrice = ({ includeTax }: Price) => {
  return (
    <div className="font-bold leading-[1.25rem] product-price font-akzidenzgrotesk text-15px text-navy">
      {includeTax.originalPriceFormatted}
    </div>
  );
};

const PricePerUnit = ({ includeTax }: Price) => {
  if (!includeTax.pricePerUnitFormatted) {
    return null;
  }

  return (
    <div className="leading-[1.25rem] font-bold font-akzidenzgrotesk text-15px my-4 text-navy">
      {includeTax.pricePerUnitFormatted}
    </div>
  );
};

export function ProductPrice({
  classes,
  prices,
  product,
  hidePriceRange,
  showPricePerUnit
}: IProductPriceProps) {
  if (!prices) {
    return null;
  }

  const { includeTax } = prices;

  const [rangeOfPrices, setRangeOfPrices] = useState<PriceTax[]>([]);

  const hasPriceRange: boolean =
    rangeOfPrices.length > 0 &&
    rangeOfPrices[0]?.price !== rangeOfPrices[1]?.price;

  useMemo(() => {
    if (!product) {
      return;
    }

    const skusPrices = [] as PriceTax[];

    product.skus.forEach((item: SkuType) => {
      if (item.prices?.length) {
        item.prices.forEach((priceSingle: Price) => {
          if (priceSingle.includeTax && priceSingle.includeTax.price) {
            skusPrices.push(priceSingle.includeTax);
          }
        });
      }
    });

    skusPrices.sort((a, b) => (a.price && b.price ? a.price - b.price : 0));

    setRangeOfPrices([skusPrices[0], skusPrices[skusPrices.length - 1]]);
  }, [product]);

  return (
    <div className={classes}>
      {(hidePriceRange || !hasPriceRange) && includeTax.isDiscounted && (
        <DiscountedPrice {...prices} />
      )}
      {!hidePriceRange && hasPriceRange && <PriceRange {...rangeOfPrices} />}
      {(hidePriceRange || !hasPriceRange) && !includeTax.isDiscounted && (
        <OriginalPrice {...prices} />
      )}
      {showPricePerUnit && <PricePerUnit {...prices} />}
    </div>
  );
}

export default ProductPrice;
