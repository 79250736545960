import { getPerlgemCartData } from '@gemini/shared/services/cart/pg-cart';
import { mapPerlgemCartData } from './mapPGCartData';
import { CartType } from './types/Cart';

const PG = 'perlgem';

const CART_PROVIDERS: { [key: string]: any } = {
  [PG]: getPerlgemCartData
};

const DATA_MAPPERS: { [key: string]: any } = {
  [PG]: mapPerlgemCartData
};

export const getCartData = async (): Promise<CartType | null> => {
  const cartDataSource = process.env.NEXT_PUBLIC_CART_DATA_SOURCE || PG;
  const cartProvider = CART_PROVIDERS[cartDataSource];
  const rawResponse = cartProvider && (await cartProvider());
  if (rawResponse) {
    const dataMapper = DATA_MAPPERS[cartDataSource];

    return dataMapper && dataMapper(rawResponse);
  }

  return null;
};
