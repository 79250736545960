import { useMemo, useState } from 'react';
import {
  PriceTax,
  ProductType,
  Shade,
  SkuType
} from '@gemini/shared/services/products/catalog';

export interface IProductShadesCountProps {
  classes?: string;
  product: ProductType;
}

export function ProductShadesCount(props: IProductShadesCountProps) {
  const { classes = '', product } = props;

  const [shadesCount, setShadesCount] = useState<number>(0);

  useMemo(() => {
    const shadesOfSkus = [] as Shade[];
    const skusPrices = [] as PriceTax[];

    product.skus.forEach((item: SkuType) => {
      if (item.shades?.length) {
        item.shades.forEach((shadeSingle: Shade) => {
          shadesOfSkus.push(shadeSingle);
        });
      }
    });

    skusPrices.sort((a, b) => (a.price && b.price ? a.price - b.price : 0));

    setShadesCount(shadesOfSkus.length);
  }, [product]);

  return (
    <>
      {shadesCount >= 1 && (
        <div
          className={`font-akzidenzgrotesk tracking-[.05em] text-base capitalize ${classes} leading-[20px]`}
        >
          {`${shadesCount} shades`}
        </div>
      )}
    </>
  );
}

export default ProductShadesCount;
