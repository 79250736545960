import { FC, useCallback, useMemo, useState } from 'react';
import {
  ISmartImageProps,
  SmartImage
} from '@gemini/shared/ui/molecules/smart-image';
import {
  CLASS_NAME_PRIMARY_VISIBLE,
  ContentHover,
  IContentHoverProps
} from './ContentHover';

export interface IImageHoverProps
  extends Omit<IContentHoverProps, 'renderPrimary' | 'renderSecondary'> {
  primaryProps: ISmartImageProps;
  secondaryProps?: ISmartImageProps;
  processPriority?: boolean;
}

export const ImageHover: FC<IImageHoverProps> = ({
  primaryProps,
  secondaryProps,
  primaryKey,
  secondaryKey,
  processPriority,
  ...rest
}) => {
  const [primaryLoaded, setPrimaryLoaded] = useState(() => false);

  const onLoad = useCallback<NonNullable<ISmartImageProps['onLoad']>>(
    (...args) => {
      setPrimaryLoaded(true);

      primaryProps?.onLoad?.(...args);
    },
    [primaryProps?.onLoad]
  );

  const loadLogicProps = useMemo<Partial<IContentHoverProps>>(() => {
    const props: Partial<IContentHoverProps> = {};

    // for images loaded with priority don't do the initial animation
    if (processPriority && primaryProps?.priority && !primaryLoaded) {
      props.classNamePrimaryHidden =
        rest.classNamePrimaryVisible || CLASS_NAME_PRIMARY_VISIBLE;
      props.classNamePrimary = '';
    }

    return props;
  }, [
    primaryLoaded,
    primaryProps?.priority,
    processPriority,
    rest.classNamePrimaryVisible
  ]);

  const onPrimaryLoadingComplete = useCallback(
    (
      onLoaded: () => void,
      ...args: Parameters<NonNullable<ISmartImageProps['onLoadingComplete']>>
    ) => {
      onLoaded();

      primaryProps?.onLoadingComplete?.(...args);
    },
    [primaryProps?.onLoadingComplete]
  );

  const onSecondaryLoadingComplete = useCallback(
    (
      onLoaded: () => void,
      ...args: Parameters<NonNullable<ISmartImageProps['onLoadingComplete']>>
    ) => {
      onLoaded();

      primaryProps?.onLoadingComplete?.(...args);
    },
    [secondaryProps?.onLoadingComplete]
  );

  return (
    <ContentHover
      {...rest}
      {...loadLogicProps}
      renderPrimary={({ onPrimaryLoaded }) => (
        <SmartImage
          {...primaryProps}
          onLoadingComplete={(...args) =>
            onPrimaryLoadingComplete(onPrimaryLoaded, ...args)
          }
          onLoad={onLoad}
        />
      )}
      renderSecondary={
        secondaryProps
          ? ({ onSecondaryLoaded }) => (
              <SmartImage
                {...secondaryProps}
                onLoadingComplete={(...args) =>
                  onSecondaryLoadingComplete(onSecondaryLoaded, ...args)
                }
              />
            )
          : undefined
      }
      primaryKey={primaryKey ?? `${primaryProps.src}`}
      secondaryKey={secondaryKey ?? `${secondaryProps?.src}`}
    />
  );
};
