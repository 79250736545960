import { useEffect } from 'react';
import type { GenericLoader } from '@gemini/shared/ui/utils/swr';

export interface IFetcherProps {
  children: JSX.Element;
  renderChildren: boolean;
  useLoader(): GenericLoader;
  renderOnLoad?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLoad(...args: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?(...args: any): void;
}

const FetchComponent = ({
  children,
  useLoader,
  renderOnLoad,
  onLoad,
  onError
}: Omit<React.PropsWithChildren<IFetcherProps>, 'renderChildren'>) => {
  const { isLoading, ...rest } = useLoader();

  useEffect(() => {
    if (!isLoading) {
      if (rest.error && onError) {
        onError(rest.error);
      } else if (!rest.error) {
        onLoad(rest.data);
      }
    }
  }, [isLoading]);

  return isLoading && !renderOnLoad ? null : children;
};

export const Fetcher = ({ renderChildren, ...props }: React.PropsWithChildren<IFetcherProps>) => {
  if (renderChildren) {
    return props.children;
  }

  return <FetchComponent {...props} />;
};

export default Fetcher;
