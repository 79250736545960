import useSWR from 'swr';
import { getCartData } from './getCartData';

export const useCart = () => {
  const { data: cart } = useSWR('GET_CART_DATA', getCartData);

  return {
    cart,
    isLoading: !cart
  };
};
