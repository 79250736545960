import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { alfrescoLoader } from '@gemini/shared/ui/utils/image';

export interface IProductBadgeProps {
  badgeText?: string | null;
  badgeSrc?: string | null;
  classes?: string;
  width?: number;
  height?: number;
}

export const ProductBadge = ({
  badgeText,
  badgeSrc,
  classes = '',
  width,
  height
}: IProductBadgeProps) => (
  <div
    className={`${classes} ${
      !badgeText ? 'no-text' : 'with-text'
    } product-badge`}
  >
    {badgeText && <span className="product-badge-text">{badgeText}</span>}
    {badgeSrc && (
      <SmartImage
        loader={alfrescoLoader}
        src={badgeSrc}
        alt={badgeText || 'Product Badge'}
        width={width}
        height={height}
        className="product-badge-image"
      />
    )}
  </div>
);

export default ProductBadge;
