import cn from 'classnames';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';

export interface IProductTitleProps {
  className?: string;
  name?: string;
  subHeader?: string;
  url?: string;
  onProductClick?: () => void;
}

export function ProductTitle(props: IProductTitleProps) {
  const { className, name, subHeader, url, onProductClick } = props;

  if (!name) {
    return null;
  }
  const subHeading = subHeader
    ? `<span class="inline-block w-full">${subHeader}</span>`
    : '';

  return (
    <h2 className={cn('h2', className)}>
      <SmartLink
        href={url ? url : ''}
        dangerouslySetInnerHTML={{
          __html: `${name} ${subHeading}`
        }}
        onClick={onProductClick}
      />
    </h2>
  );
}

export default ProductTitle;
