import { CartError } from '@gemini/shared/services/cart/cart';
import { Modal } from '@gemini/shared/ui/organisms/modal';

interface IPopOverProps {
  onClose: () => void;
  error?: CartError;
}

export function MaxItemError({ error, onClose }: IPopOverProps) {
  const isOpen = !!error?.data;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      overlay={true}
      overflow={true}
      overlayColor="bg-deep-cove"
      overlayOpacity="opacity-90"
      width="md:block w-[300px] p-[25px] sku-error-popup"
      height="h-full h-auto "
      iconConfig={{
        width: 16,
        height: 16
      }}
      closeIconPositionClasses={'top-0 right-0 opacity-40'}
      iconName="Close"
    >
      {error?.data?.messages?.map((message) => {
        return (
          <div
            key={message.key}
            className="font-akzidenzgrotesk leading-[normal] text-[15px] tracking-[.05em]"
          >
            {message.text}
          </div>
        );
      })}
    </Modal>
  );
}
export default MaxItemError;
