import {
  ICartItemEsteeLauder,
  ICartItemOrigins,
  PGCartType
} from '@gemini/shared/services/cart/pg-cart';
import { CartType } from './types/Cart';

export const perlGemCartItemMapper = (item: any) => ({
  name: item['prod.PROD_RGN_NAME'],
  subHeading: item['prod.PROD_RGN_SUBHEADING'],
  skuLabel: item['sku.label'],
  formattedDiscountedTotalPrice: item.formattedDiscountedTotalPrice,
  formattedDiscountedPrice: item.formattedDiscountedPrice,
  quantity: item.ITEM_QUANTITY,
  productUrl: item['prod.url'],
  imgUrl:
    ((item as ICartItemOrigins)['sku.SMALL_IMAGE_V2'] &&
      (item as ICartItemOrigins)['sku.SMALL_IMAGE_V2'][0]) ||
    (item as ICartItemEsteeLauder)['sku.XXS_IMAGE'],
  skuId: item['sku.SKU_ID'],
  skuBaseId: `${item['sku.SKU_BASE_ID']}`,
  skuProductSize: item['sku.PRODUCT_SIZE'],
  skuShadename: item['sku.SHADENAME'],
  skuShadeHex: item['sku.HEX_VALUE_STRING'],
  // hardcoding this for the moment
  points: null
});

export const mapPerlgemCartData = ({
  items_count,
  trans_items_count,
  subtotal_no_tax,
  subtotal,
  formattedSubtotal,
  formattedSubtotalNoTax,
  order,
  action,
  error
}: PGCartType): CartType => {
  const cartItems = order?.items.map(perlGemCartItemMapper);

  return {
    itemsCount: items_count || 0,
    transItemsCount: trans_items_count,
    subtotalNoTax: subtotal_no_tax,
    subtotal,
    formattedSubtotal,
    formattedSubtotalNoTax,
    cartItems,
    action,
    error
  };
};
