import cn from 'classnames';
import { useEffect, useState } from 'react';
import {
  IconArrowLeftLarge,
  IconArrowRightLarge
} from '@gemini/brand/el/base-theme';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { ProductType } from '@gemini/shared/services/products/catalog';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { useScreenBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { alfrescoLoader } from '@gemini/shared/ui/utils/image';

export const MAX_SHADES_VIEW_DESKTOP = 8;
export const MAX_SHADES_VIEW_MOBILE = 8;

export interface IProductShadePickerProps {
  product: ProductType;
  selectedSkuIndex: number;
  showShadeDescription?: boolean;
  changeSku: React.Dispatch<React.SetStateAction<number>>;
  showNavButtons?: boolean;
}

interface IProductShade {
  shade: any;
  isHoverAvailable: boolean;
  skuIndex: number;
  isSelected: boolean;
  changeHandler: (skuIndex: number) => void;
}

enum EventType {
  INIT = 'init',
  PREV = 'prev',
  NEXT = 'next'
}

const ProductShade = ({
  shade,
  skuIndex,
  isSelected,
  changeHandler,
  isHoverAvailable
}: IProductShade) => {
  return (
    <button
      className={cn(
        'w-[25px] md:w-[23px] h-[25px] md:h-[23px] md:mr-0.5 aspect-square border border-solid rounded-full cursor-default overflow-hidden',
        isSelected ? 'border-black' : 'border-white',
        isHoverAvailable && [
          'hover:border-gray-600 hover:border-black hover:cursor-pointer'
        ]
      )}
      onClick={() => changeHandler(skuIndex)}
      style={
        shade?.image?.src || !shade?.hexVal
          ? {}
          : { backgroundColor: shade?.hexVal }
      }
      aria-label={`Select ${shade?.name ?? ''}`}
    >
      {shade?.image?.src ? (
        <SmartImage
          src={shade.image?.src || ''}
          alt={shade.name}
          width={25}
          height={25}
          loader={alfrescoLoader}
        />
      ) : null}
    </button>
  );
};

const navButtonClass = [
  'w-8',
  'h-8',
  'rounded',
  'bg-white/50',
  'hover:bg-white/75',
  'transition',
  'absolute'
].join(' ');

const imageWrapperClass = [
  'relative',
  'scroll-smooth',
  'scrollbar',
  'w-full',
  'sm:w-[285px]',
  'flex',
  'snap-x',
  'snap-mandatory',
  'items-start',
  'lg:overflow-hidden',
  'overflow-x-scroll',
  'scrollbar-hide'
].join(' ');

export function ProductShadePickerCarousel({
  product,
  selectedSkuIndex,
  showShadeDescription,
  changeSku,
  showNavButtons = false
}: IProductShadePickerProps) {
  const [imgIdx, setImgIdx] = useState<number>(0);

  const {
    elc_general: { next: nextLabel, previous: prevLabel }
  } = useTranslations();
  const { active: bp } = useScreenBreakpoint();

  const shadesCount = product.skus.length;

  const MAX_SHADES_VIEW =
    bp === 'sm' ? MAX_SHADES_VIEW_MOBILE : MAX_SHADES_VIEW_DESKTOP;
  const moreSkusThanMaxShadesView = product.skus.length > MAX_SHADES_VIEW;

  const translateX = bp === 'sm' ? 35 : 29;

  const prevSlide = () => {
    const currentIndex = imgIdx <= 0 ? 0 : imgIdx - 1;
    if (currentIndex - MAX_SHADES_VIEW > 0) {
      setImgIdx(currentIndex - MAX_SHADES_VIEW + 1);
    } else {
      setImgIdx(0);
    }
  };

  const nextSlide = () => {
    const currentIndex =
      imgIdx >= shadesCount - 1 ? shadesCount - 1 : imgIdx + 1;
    if (currentIndex + MAX_SHADES_VIEW <= shadesCount - MAX_SHADES_VIEW) {
      setImgIdx(currentIndex + MAX_SHADES_VIEW - 1);
    } else {
      setImgIdx(shadesCount - MAX_SHADES_VIEW);
    }
  };

  const getIndexesForVisibleImages = (length: number, event: string) => {
    return Array.from(
      {
        length
      },
      (_, index) => {
        if (event === EventType.INIT) {
          return (
            index + imgIdx + (product.skus.length > MAX_SHADES_VIEW ? 1 : 0)
          );
        } else if (event === EventType.PREV) {
          const currentImgIdx = index + imgIdx - 1;

          return currentImgIdx < 0
            ? product.skus.length + currentImgIdx
            : currentImgIdx;
        } else if (event === EventType.NEXT) {
          const currentImgIdx = index + imgIdx;

          return currentImgIdx >= product.skus.length
            ? currentImgIdx - product.skus.length
            : currentImgIdx;
        } else {
          return index;
        }
      }
    );
  };

  const availableImages = moreSkusThanMaxShadesView
    ? MAX_SHADES_VIEW
    : product.skus.length;

  const [visibleImages, setVisibleImages] = useState(
    getIndexesForVisibleImages(availableImages, EventType.INIT)
  );

  const visibleStatus = () => visibleImages;

  useEffect(() => {
    const visibleImagesCopy = visibleStatus();
    if (moreSkusThanMaxShadesView) {
      if (imgIdx === visibleImagesCopy[0]) {
        setVisibleImages(
          getIndexesForVisibleImages(availableImages, EventType.PREV)
        );
      } else {
        setVisibleImages(
          getIndexesForVisibleImages(availableImages, EventType.NEXT)
        );
      }
    }
  }, [imgIdx]);

  useEffect(() => {
    if (moreSkusThanMaxShadesView) {
      const lastSlice = getIndexesForVisibleImages(
        product.skus.length,
        ''
      ).slice(product.skus.length - MAX_SHADES_VIEW, product.skus.length);
      if (lastSlice.includes(selectedSkuIndex)) {
        setImgIdx(lastSlice[0]);
      } else {
        setImgIdx(selectedSkuIndex);
      }
    }
  }, [selectedSkuIndex]);

  const getElemId = (id: number, skuId: string) => `slide${id}-sku${skuId}`;

  const carouselHandler = (skuIndex: number) => {
    setImgIdx(skuIndex);
    changeSku(skuIndex);
  };

  return (
    <>
      {product.skus.length > 1 && (
        <div className="relative">
          <div className="carousel slide sm:w-[285px] relative flex items-start z-10 lg:ml-3 md:mt-0 md:pr-[10px] mb-[5px] md:mb-0">
            <div className={imageWrapperClass}>
              <ul
                className={`flex transition-all items-start duration-500 ease-out`}
                style={{
                  transform: moreSkusThanMaxShadesView
                    ? `translateX(-${translateX * imgIdx}px)`
                    : 'none'
                }}
              >
                {product.skus.map((sku, idx: number) => (
                  <li
                    key={idx}
                    id={getElemId(idx, sku.skuId)}
                    className="flex center carousel-item relative shrink-0 snap-start md:snap-center pr-[10px] md:p-0 md:pl-0 mr-[17px] md:mr-[8px]"
                  >
                    <ProductShade
                      shade={sku.shades ? sku.shades[0] : ''}
                      skuIndex={idx}
                      isSelected={idx === selectedSkuIndex}
                      changeHandler={carouselHandler}
                      isHoverAvailable={true}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {moreSkusThanMaxShadesView && (
            <div
              className={`${
                showNavButtons ? '' : 'hidden'
              } flex absolute top-[-3px] left-[-14px] z-0 h-full w-[335px] items-center justify-between shadePickerCarousel shade-picker`}
              data-testid="carouselNav"
            >
              <button
                className={cn(navButtonClass, 'left-0 top-0 arrow-left')}
                onClick={prevSlide}
                aria-label={prevLabel || 'Previous'}
              >
                <IconArrowLeftLarge
                  name="ArrowLeftLarge"
                  width="20"
                  height="16"
                  fill={imgIdx <= 0 ? 'gray' : 'black'}
                />
              </button>
              <button
                className={cn(
                  navButtonClass,
                  'right-[-15px] top-0 arrow-right'
                )}
                onClick={nextSlide}
                aria-label={nextLabel || 'Next'}
              >
                <IconArrowRightLarge
                  name="ArrowRightLarge"
                  width="20"
                  height="16"
                  fill={
                    imgIdx + MAX_SHADES_VIEW >= shadesCount - 1
                      ? 'gray'
                      : 'black'
                  }
                />
              </button>
            </div>
          )}
        </div>
      )}

      {showShadeDescription && product.skus.length === 1 && (
        <div
          className="flex pl-[10px] md:pl-0 pb-[10px]"
          data-testid="shadeDescription"
        >
          {product.skus[0].shades && (
            <ProductShade
              shade={product.skus[0].shades ? product.skus[0]?.shades[0] : ''}
              skuIndex={1}
              isSelected={false}
              changeHandler={changeSku}
              isHoverAvailable={false}
            />
          )}
          <span className="pr-4 ml-3 mr-3 text-base">
            {product.skus[0].shades ? product.skus[0].shades[0].name : ''}
          </span>
        </div>
      )}
    </>
  );
}

export default ProductShadePickerCarousel;
