import {
  jsonRPCFetch,
  jsonRPCFetchResponse
} from '@gemini/shared/services/utils/pg-jsonrpc';
import { PGCartType } from './types/Cart';

export const CART_ADD_METHOD = 'rpc.form';

interface IParams {
  _SUBMIT: string;
  SKU_BASE_ID: string[];
  INCREMENT: number;
  QTY: number;
  action?: string;
  add_to_cart?: number;
  REPLENISHMENT_FREQ?: string;
  MONOGRAM_FONT?: string;
  MONOGRAM_TEXT?: string;
}

interface IPgAddToBagProps {
  skuId: string;
  quantity: number;
  monogram_font?:string ;
  monogram_text?: string;
  replenishment?: string;
}

export const pgAddToBag = async (
  props: IPgAddToBagProps
): Promise<PGCartType> => {
  const request: any = [];

  const { skuId, quantity, monogram_font, monogram_text, replenishment } = props;
  const params = [
    {
      _SUBMIT: 'cart',
      SKU_BASE_ID: [`${skuId}`],
      INCREMENT: 1,
      QTY: quantity,
      MONOGRAM_FONT: monogram_font,
      MONOGRAM_TEXT: monogram_text
    }
  ] as IParams[];

  if (replenishment) {
    params[0]._SUBMIT = 'alter_replenishment';
    params[0].action = 'add';
    params[0].add_to_cart = 1;
    params[0].REPLENISHMENT_FREQ = replenishment;
  }

  request.push({
    method: CART_ADD_METHOD,
    params
  });

  const url = `/api/rpc/addToCart?dbgmethod=${CART_ADD_METHOD}`;

  const res = await jsonRPCFetch({
    url,
    request,
    useCSRFTokenParam: true,
    useCSRFTokenHeader: true
  });
  const response: PGCartType = await jsonRPCFetchResponse(res as Response);

  return response;
};
