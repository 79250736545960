import cn from 'classnames';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

export interface IRenderFnConfig {
  onPrimaryLoaded(): void;

  onSecondaryLoaded(): void;
}

export type IRenderFn = (config: IRenderFnConfig) => ReactNode | undefined;

export interface IContentHoverProps {
  className?: string;

  classNamePrimaryHidden?: string;
  classNamePrimaryVisible?: string;
  classNamePrimaryDelayHide?: string;
  classNamePrimary?: string;

  classNameSecondaryHidden?: string;
  classNameSecondaryVisible?: string;
  classNameSecondary?: string;

  primaryKey?: string;
  secondaryKey?: string;

  renderPrimary: IRenderFn;
  renderSecondary?: IRenderFn;
}

export const CLASS_NAME_PRIMARY = 'transition-opacity ease-linear';
export const CLASS_NAME_PRIMARY_HIDDEN = 'opacity-0';
export const CLASS_NAME_PRIMARY_VISIBLE = 'opacity-100';
export const CLASSS_NAME_PRIMARY_DELAY_HIDE = 'delay-150';

export const CLASS_NAME_SECONDARY = 'transition-opacity ease-linear';
export const CLASS_NAME_SECONDARY_HIDDEN = 'opacity-0';
export const CLASS_NAME_SECONDARY_VISIBLE = 'opacity-100';

export const PRIMARY_KEY = 'primary';
export const SECONDARY_KEY = 'secondary';

export function ContentHover(props: IContentHoverProps) {
  const {
    className,
    classNamePrimary = CLASS_NAME_PRIMARY,
    classNamePrimaryHidden = CLASS_NAME_PRIMARY_HIDDEN,
    classNamePrimaryVisible = CLASS_NAME_PRIMARY_VISIBLE,
    classNamePrimaryDelayHide = CLASSS_NAME_PRIMARY_DELAY_HIDE,
    classNameSecondary = CLASS_NAME_SECONDARY,
    classNameSecondaryHidden = CLASS_NAME_SECONDARY_HIDDEN,
    classNameSecondaryVisible = CLASS_NAME_SECONDARY_VISIBLE,
    renderPrimary,
    renderSecondary,
    primaryKey = PRIMARY_KEY,
    secondaryKey = SECONDARY_KEY
  } = props;

  const [primaryLoaded, setPrimaryLoaded] = useState(false);
  const [secondaryLoaded, setSecondaryLoaded] = useState(false);

  const [showSecondary, setShowSecondary] = useState(false);
  const [presentSecondary, setPresentSecondary] = useState(false);

  const onPrimaryLoaded = useCallback(() => {
    setPrimaryLoaded(true);
  }, []);

  const onSecondaryLoaded = useCallback(() => {
    setSecondaryLoaded(true);
  }, []);

  const onMouseEnter = useCallback(() => {
    if (!presentSecondary) {
      setPresentSecondary(true);
    }
    if (!showSecondary) {
      setShowSecondary(true);
    }
  }, [showSecondary, presentSecondary]);
  const onMouseLeave = useCallback(() => {
    if (showSecondary) {
      setShowSecondary(false);
    }
  }, [showSecondary]);

  const onSecondaryTransitionEnd = () => {
    if (presentSecondary && !showSecondary) {
      setSecondaryLoaded(false);
      setPresentSecondary(false);
    }
  };

  const renderConfig = useMemo<IRenderFnConfig>(() => {
    return {
      onPrimaryLoaded,
      onSecondaryLoaded
    };
  }, [onPrimaryLoaded, onSecondaryLoaded]);

  useEffect(() => {
    setPrimaryLoaded(false);
  }, [primaryKey]);

  useEffect(() => {
    setSecondaryLoaded(false);
  }, [secondaryKey]);

  return (
    <div
      className={cn(className, 'relative')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        key={primaryKey}
        className={cn(
          {
            [classNamePrimaryHidden]:
              !primaryLoaded || (secondaryLoaded && showSecondary),
            [classNamePrimaryVisible]:
              primaryLoaded && secondaryLoaded && showSecondary,
            [classNamePrimaryDelayHide]:
              primaryLoaded && secondaryLoaded && showSecondary
          },
          classNamePrimary
        )}
      >
        {renderPrimary(renderConfig)}
      </div>
      {presentSecondary && renderSecondary && (
        <div
          key={secondaryKey}
          onTransitionEnd={onSecondaryTransitionEnd}
          className={cn(
            {
              [classNameSecondaryHidden]: !(secondaryLoaded && showSecondary),
              [classNameSecondaryVisible]: secondaryLoaded && showSecondary
            },
            classNameSecondary,
            'absolute top-0 w-full right-0'
          )}
        >
          {renderSecondary(renderConfig)}
        </div>
      )}
    </div>
  );
}

export default ContentHover;
