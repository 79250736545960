import {
  jsonRPCFetch,
  jsonRPCFetchResponse
} from '@gemini/shared/services/utils/pg-jsonrpc';
import { PGCartType } from './types/Cart';

export const CART_GET_METHOD = 'trans.get';

const params = [
  {
    trans_fields: ['TRANS_ID', 'totals'],
    payment_fields: [],
    order_fields: ['items', 'samples', 'offerCodes']
  }
];

export const getPerlgemCartData = async (): Promise<PGCartType> => {
  const request: any = [];

  request.push({
    method: CART_GET_METHOD,
    params
  });

  const url = `/api/rpc/getCart?dbgmethod=${CART_GET_METHOD}`;

  const res = await jsonRPCFetch({
    url,
    request,
    useCSRFTokenParam: true,
    useCSRFTokenHeader: true
  });

  const response: PGCartType = await jsonRPCFetchResponse(res as Response);

  return response;
};
