import { useAtom } from 'jotai';
import { useState } from 'react';
import {
  addToCart,
  cartAtom,
  CartError
} from '@gemini/shared/services/cart/cart';
import {
  serviceBusCommand,
  serviceBusEmit,
  ServiceBusTopics
} from '@gemini/shared/services/communication/next-elc-service-bus';
import { SkuType } from '@gemini/shared/services/products/catalog';
import { isShoppable } from '@gemini/shared/services/products/next-sd-prodcat';
import {
  isBrowser,
  isCartOverlayTooltip
} from '@gemini/shared/services/utils/global';
import { cartOverlayIsOpen } from '@gemini/shared/ui/state/cart';
import { CTAButton } from './CTAButton';

export interface IProductCtaProps {
  selectedSku: SkuType;
  quantity?: number;
  type?: string;
  buttonStyles?: string;
  onClickButtonLabel?: string;
  onAddToCart?: () => void;
  onError?: (error?: CartError) => void;
  replenishment?: string;
  monogram?: {
    text: string;
    font: string;
  };
}

interface IProductCTAPayload {
  quantity: number;
  replenishment?: string;
  skuId: string;
  monogram_font?: string;
  monogram_text?: string;
  onValidation?: () => boolean;
}

export function ProductCta({
  selectedSku,
  quantity = 1,
  type,
  buttonStyles,
  onAddToCart,
  onError,
  monogram,
  replenishment,
  onClickButtonLabel
}: IProductCtaProps) {
  const { inventoryStatus: status, skuId } = selectedSku;
  const isButtonEnabled = isShoppable(status);
  const statusButtonLabel = isButtonEnabled ? 'Add To Bag' : status;
  const [, setCart] = useAtom(cartAtom);
  const [, setIsOpen] = useAtom(cartOverlayIsOpen);
  const [buttonLabel, setButtonLabel] = useState(statusButtonLabel);

  const addToCartHandler = async () => {
    const { events, commands } = ServiceBusTopics;

    if (process.env.NEXT_PUBLIC_CART_DATA_SOURCE === 'perlgem') {
      setButtonLabel('Loading...');
      const payload: IProductCTAPayload = {
        skuId,
        quantity,
        monogram_text: monogram?.text,
        monogram_font: monogram?.font
      };
      if (replenishment && selectedSku.refillable) {
        payload.replenishment = replenishment;
      }
      const response = await addToCart(payload);
      if (onError instanceof Function) {
        onError(response.error);
      }
      setButtonLabel(onClickButtonLabel || 'Loading...');
      let cart;
      setIsOpen(true);
      if (isCartOverlayTooltip()) {
        const lastCartItem =
          response.cartItem ||
          response.cartItems?.find((item) => item.skuBaseId === skuId);
        cart = lastCartItem ? { cartItems: [lastCartItem] } : null;
      } else {
        cart = response;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setCart(cart);
      setTimeout(() => {
        setButtonLabel(statusButtonLabel);
      }, 1000);

      if (isBrowser() && !!cart) {
        window.dispatchEvent(
          new CustomEvent('addToCart.success', {
            detail: {
              ...cart
            }
          })
        );
      }
    } else {
      serviceBusCommand(commands.CART_ADD_ITEM, { skuId, quantity });
    }
    serviceBusEmit(events.CART_UPDATED, {
      newVisibility: true,
      newDisplayState: true
    });

    onAddToCart && onAddToCart();
  };

  return (
    <CTAButton
      buttonLabel={buttonLabel}
      disabled={!isButtonEnabled}
      clickHandler={addToCartHandler}
      buttonStyles={buttonStyles}
      type={type}
    />
  );
}

export default ProductCta;
