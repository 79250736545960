import cn from 'classnames';
import { ADD_TO_BAG_BTN } from '@gemini/shared/utils/testing';

interface ICTAButton {
  buttonLabel: string;
  disabled: boolean;
  clickHandler(event: React.MouseEvent<HTMLButtonElement>): void;
  type?: string;
  buttonStyles?: string;
}

export const CTAButton = ({
  buttonLabel,
  disabled,
  clickHandler,
  type,
  buttonStyles
}: ICTAButton) => {
  const btnClass = type ? `btn-cta-${type}` : `btn-cta`;

  return (
    <button
      data-testid={ADD_TO_BAG_BTN}
      className={cn(btnClass, buttonStyles, {
        [`${btnClass}--active`]: !disabled
      })}
      disabled={disabled}
      onClick={clickHandler}
    >
      {buttonLabel}
    </button>
  );
};

export default CTAButton;
