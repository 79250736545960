import ratingStyles from './product-rating.module.scss';

export interface IProductRatingProps {
  averageRating?: number;
  totalReviewCount?: number;
  isShowRating?: boolean;
  isShowReviews?: boolean;
  isTotalReviewCount?: boolean;
  classes?: string;
}

const afterClasses = [
  'after:content-[""]',
  'after:w-full',
  'after:h-[14px]',
  'after:absolute',
  'after:bg-rating-star-common',
  'after:bg-[length:14px_15px]',
  'after:block',
  'after:contrast-0',
  'top-0',
  'left-0',
  'z-1'
].join(' ');

const ratingClasses = [
  'h-full',
  'absolute',
  'top-0',
  'left-0',
  'bg-[length:14px_15px]',
  'bg-repeat-x',
  'rating-star-common',
  'z-[2]'
].join(' ');

const MIN_RATING = 0;
const MAX_RATING = 5;

export function ProductRating(props: IProductRatingProps) {
  const {
    averageRating,
    isShowRating,
    isShowReviews,
    totalReviewCount,
    isTotalReviewCount,
    classes
  } = props;

  const isValidRating = () =>
    averageRating &&
    !isNaN(averageRating) &&
    averageRating >= MIN_RATING &&
    averageRating <= MAX_RATING;

  return isValidRating() ? (
    <div
      className={`${
        ratingStyles['rating-container']
      } flex items-center rating-container mt-[10px] ${classes || ''}`}
    >
      <div
        className={`w-[70px] h-[14px] relative mr-[8px] bg-[length:14px_15px] ${afterClasses}`}
      >
        <div
          data-testid={'ratingFillLine'}
          className={ratingClasses}
          style={
            averageRating
              ? {
                  width: `${(averageRating / MAX_RATING) * 100}%`
                }
              : undefined
          }
        />
      </div>
      {isShowRating && (
        <span
          className="text-navy text-15px font-optimaroman ratings"
          data-testid={'ratingCount'}
        >
          ({averageRating})
        </span>
      )}
      {isTotalReviewCount && (
        <>
          <span
            className="product-rating-count text-navy text-15px font-medium md:mx-[8px] font-akzidenzgrotesk tracking-[0.02em] ratings leading-[20px]"
            data-testid={'ratingCount'}
          >
            ({totalReviewCount})
          </span>
        </>
      )}
      {isShowReviews && (
        <span className="py-[2px] px-[4px] text-[14px] font-akzidenzgrotesk tracking-[1.25px] font-bold">
          Reviews
        </span>
      )}
    </div>
  ) : null;
}

export default ProductRating;
