import cn from 'classnames';
import { CSSProperties, useEffect } from 'react';
import { Icon, IIconConfig } from '@gemini/shared/ui/molecules/icon';

export interface IModalProps {
  children?: Element | React.ReactNode | (() => Element | React.ReactNode);
  classNames?: string;
  closeIconPositionClasses?: string;
  closeModal?: () => void;
  contentHtml?: string;
  dataTestId?: string;
  height?: string;
  iconConfig?: Partial<IIconConfig>;
  iconName?: string;
  isOpen?: boolean;
  overflow?: boolean;
  overlay?: boolean;
  overlayColor?: string;
  overlayOpacity?: string;
  paddingBottom?: string;
  paddingTop?: string;
  style?: CSSProperties;
  width?: string;
  windowScroll?: boolean;
  hideCloseBtn?: boolean;
}

export function Modal({
  isOpen = false,
  overflow = false,
  overlay = true,
  overlayColor = '',
  overlayOpacity = '',
  width = '',
  height = '',
  paddingTop = '',
  paddingBottom = '',
  classNames = '',
  dataTestId,
  contentHtml = '',
  children,
  closeIconPositionClasses = 'top-0 right-0',
  windowScroll = true,
  closeModal = () => ({}),
  iconConfig = {
    width: 22,
    height: 22
  },
  iconName = 'CloseDirectLine',
  style = {},
  hideCloseBtn = false
}: IModalProps) {
  useEffect(() => {
    if (!windowScroll) {
      isOpen && !windowScroll && document.body.classList.add('overflow-hidden');

      return () => {
        isOpen &&
          !windowScroll &&
          document.body.classList.remove('overflow-hidden');
      };
    }

    return;
  }, [isOpen]);

  return (
    <div
      data-testid={dataTestId}
      className={cn(
        'z-[101] fixed inset-0 items-center justify-center',
        isOpen ? 'flex' : 'hidden',
        overflow ? 'overflow-scroll' : 'overflow-hidden',
        classNames
      )}
      style={style}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {overlay && (
        <div
          className={cn(
            'fixed inset-0 cursor-pointer',
            overlayColor,
            overlayOpacity,
            'modal__overlay h-[100vh]'
          )}
          onClick={closeModal}
        />
      )}
      <dialog
        open={isOpen}
        className={cn(
          'relative inset-0 bg-white p-0',
          'max-w-screen max-w-full shadow-sm',
          paddingTop ?? 'pt-0',
          paddingBottom ?? `pb-0`,
          width ?? '',
          height ?? '',
          'p-0',
          'modal__dialog'
        )}
      >
        {!hideCloseBtn && (
          <button
            aria-label="Close"
            className={cn(
              'cursor-pointer absolute p-3',
              closeIconPositionClasses,
              'modal__close'
            )}
            onClick={closeModal}
          >
            <Icon name={iconName} label="Close" {...iconConfig} />
          </button>
        )}
        <>
          {contentHtml && (
            <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
          )}
          {children instanceof Function ? children() : children}
        </>
      </dialog>
    </div>
  );
}

export default Modal;
