import { useTranslations } from '@gemini/shared/services/content/translations';

export interface IInventoryStatusMessage {
  status: string;
}

export enum InventoryStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  TEMP_OUT_OF_STOCK = 'Temporarily out of stock',
  COMING_SOON = 'Coming Soon',
  SOLD_OUT = 'Sold Out',
  FREE_PROMO = 'Free Promo'
}

type ProductInventoryStatus = {
  product: {
    temp_out_of_stock_msg: string;
    coming_soon_stock_msg: string;
    sold_out_stock_msg: string;
    inactive_stock_msg: string;
  };
};

export const InventoryStatusMessage = ({ status }: IInventoryStatusMessage) => {
  const { product } = useTranslations() as unknown as ProductInventoryStatus;

  const inventoryStatusMappings: { [k: string]: string } = {
    [InventoryStatus.TEMP_OUT_OF_STOCK]: product.temp_out_of_stock_msg,
    [InventoryStatus.COMING_SOON]: product.coming_soon_stock_msg,
    [InventoryStatus.SOLD_OUT]: product.sold_out_stock_msg,
    [InventoryStatus.INACTIVE]: product.inactive_stock_msg
  };

  const inventoryMessage = inventoryStatusMappings[status];

  return <>{inventoryMessage}</>;
};

export default InventoryStatusMessage;
