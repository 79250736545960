import { useRef, useState } from 'react';
import {
  IImage,
  IVideo,
  IVideoAsset
} from '@gemini/shared/services/products/catalog';
import { useInViewport } from '@gemini/shared/ui/utils/hooks';
import { MediaView } from './MediaView';

export interface IImageSliderProps {
  media: IImage | IVideo | IVideoAsset;
  priority?: boolean;
  isVertical?: boolean;
}

export const ImageSlider = (props: IImageSliderProps) => {
  const { media, priority, isVertical = false } = props;
  const [preview, setPreview] = useState(true);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { inViewport } = useInViewport(
    buttonRef,
    { rootMargin: '0px' },
    { disconnectOnLeave: false }
  );

  return (
    <>
      {isVertical ? (
        <button ref={buttonRef} className={'w-[68px] h-[68px] p-[4px]'}>
          <span className={`block relative`}>
            <MediaView
              media={media}
              size={60}
              priority={priority ?? inViewport}
            />
          </span>
        </button>
      ) : (
        <MediaView
          media={media}
          size={600}
          priority={priority ?? inViewport}
          preview={{ state: preview, handlePreview: setPreview }}
        />
      )}
    </>
  );
};
