import { useEffect, useRef } from 'react';
import {
  IImage,
  IVideo,
  IVideoAsset
} from '@gemini/shared/services/products/catalog';
import { YouTubeFrame } from '@gemini/shared/ui/atoms/youtube-frame';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { useInViewport } from '@gemini/shared/ui/utils/hooks';
import { Video } from './Video';

interface IMediaView {
  media: IImage | IVideo | IVideoAsset;
  size: number;
  priority: boolean;
  preview?: {
    state: boolean;
    handlePreview: (prev: boolean) => void;
  };
}

interface IYoutubeWrapperProps {
  source: string;
  preview?: IMediaView['preview'];
}

const YoutubeWrapper = ({ source, preview }: IYoutubeWrapperProps) =>
  source ? (
    <div className="relative">
      <YouTubeFrame source={source} width={'500'} height={'500'} lazy />
      <span
        className="absolute top-0 right-0 p-2 bg-gray-100 font-2xl"
        onClick={() => preview?.handlePreview(true)}
      >
        &times;
      </span>
    </div>
  ) : null;

export const MediaView = ({ media, size, priority, preview }: IMediaView) => {
  const { source = '' } = media as IVideo;
  const myRef = useRef<HTMLDivElement>(null);
  const { inViewport } = useInViewport(
    myRef,
    { rootMargin: '0px' },
    { disconnectOnLeave: false }
  );

  useEffect(() => {
    if (!inViewport) {
      preview?.handlePreview(true);
    }
  }, [inViewport]);

  const videoMedia = !media?.type ? (media as IVideoAsset) : null;

  return (
    <>
      {videoMedia && videoMedia.provider === 'YouTube' && (
        <Video
          size={size}
          preview={preview}
          media={videoMedia}
          priority={priority}
        />
      )}
      {media?.type === 'image' && (
        <SmartImage
          src={media.src || ''}
          alt={media.alt || 'Product Image'}
          width={size}
          height={size}
          priority={priority}
        />
      )}
      {media?.type === 'video' && (
        <>
          {preview ? (
            <>
              {preview?.state ? (
                <>
                  <SmartImage
                    src={media.src || ''}
                    alt={media.alt || 'Video thumbnail'}
                    width={size}
                    height={size}
                    priority={priority}
                  />
                  <div
                    className="absolute inset-0 z-[2] cursor-pointer flex items-center justify-center"
                    onClick={() => preview?.handlePreview(false)}
                  >
                    <span className="play-btn" />
                  </div>
                </>
              ) : (
                <YoutubeWrapper source={source} preview={preview} />
              )}
            </>
          ) : (
            <>
              <SmartImage
                src={media.src || ''}
                alt={media.alt || 'Video thumbnail'}
                width={size}
                height={size}
                priority={priority}
              />
              <div className="absolute inset-0 z-[2] cursor-pointer flex items-center justify-center">
                <span className="play-btn" />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
