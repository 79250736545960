import { IconPlay, IconPlaySmall } from '@gemini/brand/el/base-theme';
import { IVideoAsset } from '@gemini/shared/services/products/catalog';
import { YouTubeFrame } from '@gemini/shared/ui/atoms/youtube-frame';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { alfrescoLoader } from '@gemini/shared/ui/utils/image';

interface IVideo {
  media: IVideoAsset;
  size: number;
  priority: boolean;
  preview?: {
    state: boolean;
    handlePreview: (prev: boolean) => void;
  };
}

const VideoPreview = (props: IVideo) => {
  const { preview, media, size, priority } = props;

  return preview ? (
    <>
      <SmartImage
        loader={alfrescoLoader}
        src={`/${media.poster.src}`}
        alt={'Video thumbnail'}
        width={size}
        height={size}
        priority={priority}
      />
      <div
        className="absolute inset-0 z-[20] cursor-pointer flex items-center justify-center opacity-80 hover:opacity-100"
        onClick={() => preview?.handlePreview(false)}
      >
        <IconPlay
          title="Play"
          fill={'#FFFFFF'}
          color={'#FFFFFF'}
          width={90}
          height={90}
        />
      </div>
    </>
  ) : (
    <>
      <SmartImage
        loader={alfrescoLoader}
        src={`/${media.poster.src}`}
        alt={media.alt || 'Video thumbnail'}
        width={size}
        height={size}
        priority={priority}
      />
      <div className="absolute inset-0 z-[2] cursor-pointer flex items-center justify-center pointer-events-auto">
        <IconPlaySmall
          title="Play"
          fill={'#FFFFFF'}
          color={'#FFFFFF'}
          width={36}
          height={36}
        />
      </div>
    </>
  );
};

interface IYoutubeWrapperProps {
  source: string;
}

const YoutubeWrapper = ({ source }: IYoutubeWrapperProps) =>
  source ? (
    <div className="relative">
      <YouTubeFrame source={source} width="380px" height="380px" autoplay />
    </div>
  ) : null;

export const Video = (props: IVideo) => {
  const { preview, media } = props;

  return (
    <>
      {preview ? (
        <>
          {preview?.state ? (
            <VideoPreview {...props} />
          ) : (
            <YoutubeWrapper source={media.src} />
          )}
        </>
      ) : (
        <VideoPreview {...props} />
      )}
    </>
  );
};
