import cn from 'classnames';
import { useState } from 'react';
import Slider from 'react-slick';
import { IconArrowLeft, IconArrowRight } from '@gemini/brand/el/base-theme';
import { ProductBadge } from '@gemini/brand/el/ui/molecules/product-badge';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { IImage, IVideo } from '@gemini/shared/services/products/catalog';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { ImageSlider } from './ImageSlider';

export interface IMediaViewProps {
  media: (IImage | IVideo)[] | undefined;
  priority?: boolean;
}

export interface IProductImageCarouselProps extends IMediaViewProps {
  showThumbnails?: boolean;
  backgroundSrc?: string;
  imageWrapperClass?: string;
  lensPos?: {
    top: string;
    left: string;
  };
  desktopSliderMiniatures?: number;
  lensSize?: number;
  badgeSrc?: string | null;
  badgeText?: string | null;
  infinite?: boolean;
  productImageMiniatureClass?: string;
  productImageClass?: string;
  showSlider?: boolean;
  squareAspectRatio?: boolean;
}

const getElemId = (id: number) => `slide${id}`;
const LENS_SIZE = 200;

const imageMiniaturesClass =
  'carousel-miniatures my-[16px] mb-[56px] w-full w-[68px] max-h-[410px]';

export const ProductImageCarousel = (
  props: IProductImageCarouselProps
): JSX.Element => {
  const {
    backgroundSrc,
    imageWrapperClass = '',
    lensPos,
    lensSize = LENS_SIZE,
    media: originalImages = [],
    priority,
    showThumbnails = true,
    badgeSrc,
    badgeText,
    productImageMiniatureClass = '',
    productImageClass = '',
    showSlider = true
  } = props;

  const {
    elc_general: { next: _nextLabel, previous: _prevLabel }
  } = useTranslations();

  const nextLabel = _nextLabel || 'Next';
  const prevLabel = _prevLabel || 'Previous';

  const miniatureSettings = {
    speed: 300,
    cssEase: 'linear',
    infinite: originalImages?.length > 4,
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    focusOnSelect: true,
    swipeToSlide: true,
    prevArrow: (
      <button aria-label={prevLabel}>
        <Icon name="ArrowUp" width="32" height="32" />
      </button>
    ),
    nextArrow: (
      <button aria-label={nextLabel}>
        <Icon name="ArrowDown" width="32" height="32" />
      </button>
    )
  };
  const settings = {
    speed: 300,
    cssEase: 'linear',
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: (
      <button aria-label={prevLabel}>
        <IconArrowLeft name="ArrowLeft" width="34" height="34" />
      </button>
    ),
    nextArrow: (
      <button aria-label={nextLabel}>
        <IconArrowRight name="ArrowRight" width="34" height="34" />
      </button>
    )
  };

  const [productNav, setProductNav] = useState<Slider>();
  const [miniatureNav, setMiniatureNav] = useState<Slider>();

  return (
    <>
      {showThumbnails && (
        <div className={cn(imageMiniaturesClass, productImageMiniatureClass)}>
          <Slider
            {...miniatureSettings}
            asNavFor={miniatureNav ?? undefined}
            ref={(slider: Slider) => setProductNav(slider)}
          >
            {originalImages?.map((media) => (
              <ImageSlider media={media} priority={priority} isVertical />
            ))}
          </Slider>
        </div>
      )}
      <div
        className={cn(
          `relative md:mb-[30px] product-image-wrapper`,
          productImageClass
        )}
      >
        <div className={`${imageWrapperClass}`}>
          <Slider
            {...settings}
            asNavFor={productNav ?? undefined}
            ref={(slider: Slider) => setMiniatureNav(slider)}
          >
            {originalImages?.map(
              (media: IImage | IVideo, idx: number) =>
                (showSlider || idx === 0) && (
                  <div
                    key={idx}
                    id={getElemId(idx)}
                    className="relative w-full carousel-item shrink-0 snap-center"
                  >
                    {media?.type === 'image' && Boolean(backgroundSrc) && (
                      <div
                        className="absolute z-10 transition-all bg-white border border-gray-300 opacity-25 pointer-events-none"
                        style={{
                          width: `${lensSize}px`,
                          height: `${lensSize}px`,
                          ...lensPos
                        }}
                      />
                    )}
                    {(badgeSrc || badgeText) && idx === 0 && (
                      <ProductBadge
                        badgeSrc={badgeSrc}
                        badgeText={badgeText}
                        classes="product-image-carousel"
                        width={92}
                        height={110}
                      />
                    )}
                    <ImageSlider media={media} priority={priority} />
                  </div>
                )
            )}
          </Slider>
        </div>
      </div>
    </>
  );
};
