import { useMemo, useState } from 'react';
import { ProductBadge } from '@gemini/brand/el/ui/molecules/product-badge';
import { IImage, SkuType } from '@gemini/shared/services/products/catalog';
import { ImageHover } from '@gemini/shared/ui/molecules/image-hover';
import { Size } from '@gemini/shared/ui/molecules/product-brief-image';
import { ISmartImageProps } from '@gemini/shared/ui/molecules/smart-image';

interface IProductBriefImage {
  sku: SkuType;
  displayName?: string;
  badgeText?: string | null;
  badgeSrc?: string | null;
  small?: boolean;
  showBadge?: boolean;
  size?: Size;
  priority?: boolean;
}

const CLASS_NAME_SECONDARY = 'transition-opacity ease-linear duration-50';

export const ProductBriefImage = ({
  sku,
  badgeText,
  badgeSrc,
  showBadge = true,
  size = Size.medium,
  displayName,
  priority
}: IProductBriefImage) => {
  const [showAlt, setShowAlt] = useState(false);
  const skuMedia = sku.media[size];
  const primaryImage = skuMedia ? skuMedia[0] : ({} as IImage);
  const altImage = skuMedia ? skuMedia[1] : ({} as IImage);

  const showAltImage = altImage && showAlt;
  const imageProp = showAltImage ? altImage : primaryImage;

  const primaryProps = useMemo<ISmartImageProps | undefined>(() => {
    const media = skuMedia?.[0];

    return media
      ? {
          height: media.height,
          width: media.width,
          alt: displayName || '',
          src: media.src,
          priority,
          loading: priority ? undefined : 'lazy'
        }
      : undefined;
  }, [skuMedia?.[0]]);

  const secondaryProps = useMemo<ISmartImageProps | undefined>(() => {
    const media = skuMedia?.[1];

    return media
      ? {
          height: media.height,
          width: media.width,
          alt: displayName || '',
          src: media.src
        }
      : undefined;
  }, [skuMedia?.[1]]);

  return (
    <div className="relative">
      {primaryProps && (
        <ImageHover
          processPriority
          primaryProps={primaryProps}
          secondaryProps={secondaryProps}
          classNameSecondary={CLASS_NAME_SECONDARY}
          className="product-image"
        />
      )}
      {showBadge && (badgeText || badgeSrc) ? (
        <ProductBadge
          badgeText={badgeText}
          badgeSrc={badgeSrc}
          classes="product-brief-image"
          width={68}
          height={80}
        />
      ) : null}
    </div>
  );
};

export default ProductBriefImage;
