import { useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { SkuType } from '@gemini/shared/services/products/catalog';
import { isShoppable } from '@gemini/shared/services/products/next-sd-prodcat';
import {
  loyaltyNotifyModal,
  ModalPosition
} from '@gemini/shared/ui/state/loyalty-notify';
import { CTAButton } from './CTAButton';

export interface IProductNotifyProps {
  selectedSku: SkuType;
  quantity?: number;
  type?: string;
  buttonStyles?: string;
  onClickButtonLabel?: string;
}

export function ProductNotify({
  selectedSku,
  type,
  buttonStyles,
  onClickButtonLabel
}: IProductNotifyProps) {
  const { inventoryStatus: status } = selectedSku;
  const isButtonEnabled = isShoppable(status);
  const translations = useTranslations();

  // @todo: remove this after fix translation text
  const tmpTranslations = {
    loading: 'Loading...',
    notify_me: 'Notify Me'
  };

  const {
    elc_product: { loading = tmpTranslations.loading },
    product: { notify_me = tmpTranslations.notify_me }
  } = translations;

  const statusButtonLabel = isButtonEnabled
    ? notify_me || tmpTranslations.notify_me
    : status;
  const [buttonLabel, setButtonLabel] = useState(statusButtonLabel);
  const [loyaltyNotifyModalState, setLoyaltyNotifyModalState] =
    useAtom(loyaltyNotifyModal);

  const notifyMeHandler = async () => {
    setButtonLabel(loading);

    setLoyaltyNotifyModalState({ isOpen: true, position: ModalPosition.TOP });

    setButtonLabel(onClickButtonLabel || loading);

    setTimeout(() => {
      setButtonLabel(statusButtonLabel);
    }, 1000);
  };

  return (
    <CTAButton
      buttonLabel={buttonLabel}
      disabled={!isButtonEnabled}
      clickHandler={notifyMeHandler}
      buttonStyles={buttonStyles}
      type={type}
    />
  );
}

export default ProductNotify;
