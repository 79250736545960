import { pgAddToBag } from '@gemini/shared/services/cart/pg-cart';
import { mapPerlgemCartData, perlGemCartItemMapper } from './mapPGCartData';
import { CartType } from './types/Cart';

const PG = 'perlgem';

const CART_PROVIDERS: { [key: string]: any } = {
  [PG]: pgAddToBag
};

const DATA_MAPPERS: { [key: string]: any } = {
  [PG]: mapPerlgemCartData
};

type AddToCartPayloadType = {
  skuId: string;
  quantity: number;
  replenishment?: string;
  monogram_text?: string;
  monogram_font?: string;
};

export const addToCart = async (
  payload: AddToCartPayloadType
): Promise<CartType> => {
  const cartDataSource = process.env.NEXT_PUBLIC_CART_DATA_SOURCE || PG;
  const cartProvider = CART_PROVIDERS[cartDataSource];
  const rawResponse = cartProvider && (await cartProvider(payload));
  const dataMapper = DATA_MAPPERS[cartDataSource];
  const action =
    rawResponse?.ac_results?.length && rawResponse.ac_results[0].action;
  const response =
    dataMapper &&
    dataMapper({
      ...rawResponse?.trans_data,
      action,
      error: rawResponse?.error
    });

  const item = rawResponse?.ac_results?.[0]?.result?.CARTITEM;
  const cartItem = item ? perlGemCartItemMapper(item) : null;

  return { ...response, cartItem };
};
