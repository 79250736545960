import { useEffect, useState } from 'react';
import { SkuType } from '@gemini/shared/services/products/catalog';
import { Dropdown } from '@gemini/shared/ui/molecules/dropdown';
import { IDropdownItem } from '@gemini/shared/ui/molecules/dropdown-item';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { alfrescoLoader } from '@gemini/shared/ui/utils/image';
import ProductShadePickerDropdownStyle from './ProductShadePickerDropdown.module.scss';

interface ISelectedShade extends IDropdownItem {
  id: string;
}

export interface IProductShadePickerDropdownProps {
  skus: SkuType[];
  changeSku: React.Dispatch<React.SetStateAction<number>>;
  selectedSkuIndex: number;
  dropdownCallback?: (value: boolean) => void;
}

export function ProductShadePickerDropdown(
  props: IProductShadePickerDropdownProps
) {
  const { skus, changeSku, selectedSkuIndex, dropdownCallback } = props;

  const [selectedShade, setSelectedShade] = useState<SkuType>();

  useEffect(() => {
    setSelectedShade(skus[selectedSkuIndex]);
  }, [props.skus, selectedSkuIndex]);

  const findSku = (searchValue: string | ISelectedShade) => {
    const searchedSku = skus.find((item: SkuType) => {
      return (
        item.skuId === searchValue ||
        (item.shades && item.shades[0].hexVal === searchValue)
      );
    });

    return searchedSku;
  };

  const onDropdownChange = (value: ISelectedShade) => {
    const searchValue = value.id || value;
    const selectedSku = findSku(searchValue);

    setSelectedShade(selectedSku);
    selectedSku && changeSku(skus.indexOf(selectedSku));
  };

  const dropdownData = skus.map((item: SkuType) => {
    const shade = item.shades?.[0];

    return {
      status: item.inventoryStatus.toLowerCase(),
      label: shade?.name || '',
      value: shade?.hexVal || '',
      src: shade?.image?.src || '',
      id: item.skuId
    };
  });

  const setStatusClasses = (status: string) => {
    if (status === 'sold out' || status === 'temporarily out of stock') {
      return 'before:top-[10px] before:content before:absolute before:h-[0.1rem] before:bg-white before:rotate-45';
    }

    return '';
  };

  const setDropdownIcon = (shadeData: IDropdownItem) => {
    return (
      <div
        className={`
         ${setStatusClasses(shadeData.status)}
          before:w-[20px] before:top-[8px] before:left-[0px] before:block
          w-[22px] h-[22px] ml-[8px] mr-[16px] rounded-full overflow-hidden relative`}
        style={shadeData.value ? { background: shadeData.value } : {}}
      >
        {shadeData.src ? (
          <SmartImage
            src={shadeData.src}
            alt={shadeData.name}
            width={23}
            height={23}
            loader={alfrescoLoader}
          />
        ) : null}
      </div>
    );
  };

  const dropdownStyles = {
    selectedItemClassName:
      'border-black max-h-[40px] pr-0 py-1 product-size-dropdown-item-selected uppercase ',
    selectedItemContainerClassName:
      'border border-black pr-0 product-size-dropdown-item-container-selected uppercase h-[40px]',
    itemClassName:
      'p-4 max-h-9 hover:bg-gray-100 bg-white border-l border-r border-gray-300 uppercase ',
    listClassName: `border-none rounded-none max-h-[40px] text-base`,
    mobileListClassName:
      'border border-black rounded-none w-[100%] px-3 py-1.5 bg-transparent uppercase product-size-dropdown-mobile',
    mobileFooterClassName: 'min-w-[150px] block md:hidden',
    dropdownListClasses: 'border-gray-300 border-b'
  };

  return (
    <>
      <Dropdown
        {...dropdownStyles}
        dropdownCallback={dropdownCallback}
        listClassName={`border-black w-328px rounded-none z-10 mb-[5px] mt-[10px] uppercase ${ProductShadePickerDropdownStyle.dropdown}`}
        dropdownListClasses={
          'h-52 border border-silver text-15px overflow-y-scroll scrollbar z-10 bg-white'
        }
        dropdownItemClasses={'border border-black'}
        selectedItemClassName={'text-navy text-[15px]'}
        handler={onDropdownChange}
        cssDropdownArrow={true}
        selected={
          selectedShade && selectedShade.shades
            ? {
                label: selectedShade.shades[0].hexVal
                  ? selectedShade.shades[0].name
                  : '',
                value: selectedShade.shades[0].hexVal
                  ? selectedShade.shades[0].hexVal
                  : '',
                src: selectedShade.shades[0].image
                  ? selectedShade.shades[0].image.src
                  : '',
                id: selectedShade.skuId
              }
            : { label: '', value: '', id: '', src: '' }
        }
        list={dropdownData}
        setDropdownIcon={setDropdownIcon}
      />
    </>
  );
}

export default ProductShadePickerDropdown;
