import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ProductBriefImage } from '@gemini/brand/el/ui/molecules/product-brief-image';
import { ProductDescription } from '@gemini/brand/el/ui/molecules/product-description';
import { ProductPrice } from '@gemini/brand/el/ui/molecules/product-price';
import { ProductRating } from '@gemini/brand/el/ui/molecules/product-rating';
import { ProductShadesCount } from '@gemini/brand/el/ui/molecules/product-shades-count';
import { ProductTitle } from '@gemini/brand/el/ui/molecules/product-title';
import { ProductImageCarousel } from '@gemini/brand/el/ui/organisms/product-image-carousel';
import type { IProductQuickShopProps } from '@gemini/brand/el/ui/organisms/product-quick-shop';
import { ProductShadePickerCarousel } from '@gemini/brand/el/ui/organisms/product-shade-picker-carousel';
import { ProductShadePickerDropdown } from '@gemini/brand/el/ui/organisms/product-shade-picker-dropdown';
import {
  trackProductClick,
  trackProductQuickView
} from '@gemini/shared/services/analytics/events';
import { CartError } from '@gemini/shared/services/cart/cart';
import { useTranslations } from '@gemini/shared/services/content/translations';
import {
  IImage,
  IVideo,
  ProductType
} from '@gemini/shared/services/products/catalog';
import { Dropdown } from '@gemini/shared/ui/molecules/dropdown';
import { InventoryStatusMessage } from '@gemini/shared/ui/molecules/inventory-status-message';
import { Size } from '@gemini/shared/ui/molecules/product-brief-image';
import {
  MaxItemError,
  ProductCta
} from '@gemini/shared/ui/molecules/product-cta';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { Modal } from '@gemini/shared/ui/organisms/modal';
import { dynamicClient } from '@gemini/shared/ui/utils/dynamic-load';
import { QUICK_SHOP_BTN, QUICK_SHOP_MODAL } from '@gemini/shared/utils/testing';
import productCardStyles from './product-card.module.scss';

const cl = (className: string) => productCardStyles[className] ?? '';

const ProductQuickShop = dynamicClient<IProductQuickShopProps>(() =>
  import('@gemini/brand/el/ui/organisms/product-quick-shop').then(
    (m) => m.ProductQuickShop
  )
);

export interface IProductCardProps {
  className?: string;
  imagePriority?: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  positionIndex: number;
  product: ProductType;
  productImageCarousel?: boolean;
  showBadge?: boolean;
  showCta?: boolean;
  showDetails?: boolean;
  showPrice?: boolean;
  showProductRating?: boolean;
  showShadeDescription?: boolean;
  showShadeNavButtons?: boolean;
  showSubtitle?: boolean;
  showTitle?: boolean;
  onError?: (error?: CartError) => void;
}

type MediaType = {
  large?: IImage[];
  medium?: IImage[];
  small?: IImage[];
  video?: IVideo[];
};

const sanitizeSelection = (selection: string) => {
  return selection.replace(/\s/g, ' ').split(' ').join('_');
};

const fullUrlBuild = (urlParams: URLSearchParams, url: string) =>
  urlParams.toString() ? `${url}?${urlParams.toString()}` : url;

const getProductImages = (
  productMedia: MediaType | undefined,
  skuMedia: MediaType | undefined
) => {
  if (productMedia && skuMedia) {
    if (productMedia.large && skuMedia.large) {
      return [...skuMedia.large, ...productMedia.large];
    } else if (productMedia.large) {
      return productMedia.large;
    } else if (skuMedia.large) {
      return skuMedia.large;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

const dropdownStyles = {
  selectedItemClassName:
    'border-black max-h-[40px] pr-0 py-1 product-size-dropdown-item-selected uppercase ',
  selectedItemContainerClassName:
    'border border-black pr-0 product-size-dropdown-item-container-selected uppercase',
  itemClassName:
    'p-4 max-h-9 hover:bg-gray-100 bg-white border-l border-r border-gray-300 uppercase ',
  listClassName: `border-none rounded-none max-h-[40px] text-base`,
  mobileListClassName:
    'border border-black rounded-none w-[100%] px-3 py-1.5 bg-transparent uppercase product-size-dropdown-mobile',
  mobileFooterClassName: 'min-w-[150px] block md:hidden',
  dropdownListClasses: 'border-gray-300 border-b'
};

export function ProductCard({
  imagePriority,
  isDesktop = true,
  isMobile = true,
  positionIndex,
  product,
  productImageCarousel = true,
  showBadge = true,
  showDetails = true,
  showShadeDescription = true,
  showShadeNavButtons = false,
  onError
}: IProductCardProps) {
  const {
    elc_product: { quick_shop: quickShopLabel }
  } = useTranslations();

  const [selectedSkuIndex, setSelectedSkuIndex] = useState(0);
  const [error, setError] = useState<CartError>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [domElem, setDomElem] = useState<HTMLElement | null>(null);

  const selectedSku = product.skus[selectedSkuIndex];

  const sizes = [
    ...new Set(product.skus.map((sku) => sku.sizes && sku.sizes[0].value))
  ];

  const dropdownList = product.skus.map((sku) => {
    return {
      label: (sku.sizes || [])[0].value
    };
  });

  const { url = '' } = product;

  const hasMultipleShades = (selectedSku.shades || []).length > 0;
  const urlParams = new URLSearchParams();
  if (hasMultipleShades) {
    const shadeName = selectedSku.shades?.[0].name;
    if (shadeName) {
      urlParams.set('shade', sanitizeSelection(shadeName));
    }
  } else {
    const sizeName = selectedSku.sizes?.[0]?.value;
    if (sizeName) {
      urlParams.set('size', sanitizeSelection(sizeName));
    }
  }

  const onProductClick = () => {
    trackProductClick(product, positionIndex);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
    trackProductQuickView(product, positionIndex);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const elem = document.querySelector('body') as HTMLElement;
    setDomElem(elem);
  }, []);

  const handleError = (cartError?: CartError) => {
    if (onError instanceof Function) {
      onError(cartError);
    } else {
      setError(cartError);
    }
  };

  const createPortal = () => {
    return isModalOpen ? (
      <Modal
        dataTestId={QUICK_SHOP_MODAL}
        isOpen={isModalOpen}
        closeModal={onModalClose}
        overlay={true}
        overflow={true}
        overlayColor={'bg-black'}
        overlayOpacity={'opacity-40'}
        width={'hidden md:block md:11/12 lg:max-w-[920px] px-[30px]'}
        windowScroll={false}
        paddingTop={'pt-[42px]'}
        paddingBottom={'pb-[30px]'}
        classNames={cl('quickshop-modal')}
        iconConfig={{ width: 20, height: 20 }}
      >
        <ProductQuickShop
          product={product}
          positionIndex={positionIndex}
          selectedSkuIndex={selectedSkuIndex}
          setSelectedSkuIndex={setSelectedSkuIndex}
          onAddToCart={onModalClose}
          onError={handleError}
        />
      </Modal>
    ) : null;
  };

  const ModalPortal = () =>
    domElem ? ReactDOM.createPortal(createPortal(), domElem) : undefined;

  const ProductImage =
    isMobile && productImageCarousel ? (
      <div className={'relative'}>
        <ProductImageCarousel
          media={getProductImages(product.media, selectedSku.media)}
          showThumbnails={false}
          showSlider={productImageCarousel}
          priority={false}
          badgeText={product.productBadge}
          badgeSrc={product.productBadgeImg}
          infinite={false}
        />
      </div>
    ) : (
      <div className={'relative'}>
        <ProductBriefImage
          sku={selectedSku}
          displayName={product.displayName}
          badgeText={product.productBadge}
          badgeSrc={product.productBadgeImg}
          showBadge={showBadge}
          priority={imagePriority}
          size={isMobile ? Size.large : Size.medium}
        />
      </div>
    );

  const [openDropdownStatus, setOpenDropdownStatus] = useState(false);

  const fullUrl = fullUrlBuild(urlParams, url);

  return (
    <>
      <MaxItemError error={error} onClose={() => setError(undefined)} />
      {isModalOpen && isDesktop ? ModalPortal() : null}
      <div
        data-product-id={product.productId}
        className={`flex justify-center max-w-lg w-[100%] mx-auto sm:max-w-[19rem] md:max-w-[19.27rem] md:py-[10px] z-1 relative ${cl(
          'product-container'
        )} product-card ${openDropdownStatus ? 'z-[100]' : ''}`}
      >
        <div className={'flex flex-col w-full'}>
          <div className={'relative mb-[10px]'}>
            {!isMobile && (
              <button
                data-testid={QUICK_SHOP_BTN}
                className={
                  'quickshop-button absolute left-0 right-0 bottom-2/4 mt-[-16px] z-10 my-0 mx-auto h-8 px-5 border border-black text-link bg-white w-[132px] font-bold hidden uppercase tracking-[1px]'
                }
                onClick={onModalOpen}
                aria-label={product.displayName}
              >
                {quickShopLabel}
              </button>
            )}
            <SmartLink
              href={fullUrl}
              classes={`relative z-1 after:bg-violet-700 ${cl(
                'product-container--link'
              )}`}
              onClick={onProductClick}
            >
              {ProductImage}
            </SmartLink>
          </div>
          <div className="relative z-10 flex flex-col flex-1 mr-0 md:pl-0 md:mx-0 product-container-bottom md:w-auto">
            {showDetails && (
              <div className="">
                {!!selectedSku.shades?.length && product.skus.length > 1 && (
                  <div className="flex flex-col ml-[5px] md:ml-0 md:mb-[10px]">
                    <ProductShadePickerCarousel
                      product={product}
                      selectedSkuIndex={selectedSkuIndex}
                      changeSku={setSelectedSkuIndex}
                      showShadeDescription={showShadeDescription}
                      showNavButtons={showShadeNavButtons}
                    />
                  </div>
                )}
                {!!selectedSku.shades?.length && product.skus.length === 1 && (
                  <div className="flex flex-col">
                    <ProductShadePickerCarousel
                      product={product}
                      selectedSkuIndex={selectedSkuIndex}
                      changeSku={() => ({})}
                      showShadeDescription={true}
                      showNavButtons={showShadeNavButtons}
                    />
                  </div>
                )}
                <div className="flex flex-col flex-1 pl-[10px] pt-[10px] md:pl-0 md:pt-0 md:mr-[10px]">
                  <ProductTitle
                    className="product-title text-[21px] md:text-[22px] font-optimaroman text-navy leading-[24px] tracking-[0.75px]"
                    name={product.displayName}
                    subHeader={product.subHeader}
                    onProductClick={onProductClick}
                    url={fullUrl}
                  />

                  <ProductRating
                    averageRating={product.averageRating}
                    totalReviewCount={product.totalReviewCount}
                    isTotalReviewCount
                    classes="py-0"
                  />

                  <ProductDescription
                    className="product-description description-copy font-medium mt-[10px] text-base font-500 font-akzidenzgrotesk leading-[20px] tracking-[0.1px] text-navy"
                    text={product.shortDescription}
                    url={fullUrl}
                  />

                  <ProductPrice
                    classes="text-navy tracking-[1px] md:tracking-normal my-[10px]"
                    prices={selectedSku.prices[0]}
                    product={product}
                  />

                  <ProductShadesCount classes={'mb-[10px]'} product={product} />

                  {/* BEGIN: experimental hidden feature */}
                  {/* this was copied from ProductQuickShop for the sake of experimentation */}
                  {/* if unhidden, this will need to be cleaned up and moved to a shared organism */}
                  <div
                    className={`hidden product-size-picker ${productCardStyles['product-size-dropdown']}`}
                  >
                    {sizes.length > 1 ? (
                      <Dropdown
                        dropdownCallback={setOpenDropdownStatus}
                        {...dropdownStyles}
                        cssDropdownArrow={true}
                        selected={dropdownList[selectedSkuIndex]}
                        list={dropdownList}
                        handler={(item, index) => setSelectedSkuIndex(index)}
                        dropUp={false}
                      />
                    ) : null}
                  </div>
                  {/* END: experimental hidden feature */}

                  {/* BEGIN: experimental hidden feature */}
                  <div
                    className={`hidden mt-2 product-shade-picker-dropdown mb-5 lg:mb-0 ${productCardStyles['product-shade-picker-dropdown']}`}
                  >
                    {selectedSku.shades?.length ? (
                      <ProductShadePickerDropdown
                        dropdownCallback={setOpenDropdownStatus}
                        skus={product.skus}
                        selectedSkuIndex={selectedSkuIndex}
                        changeSku={setSelectedSkuIndex}
                      />
                    ) : null}
                  </div>
                  {/* END: experimental hidden feature */}

                  {/* BEGIN: experimental hidden feature */}
                  {/* this was copied from ProductQuickShop for the sake of experimentation */}
                  {/* if unhidden, this will need to be cleaned up and moved to a shared organism */}
                  <div className="hidden product-cta my-[10px]">
                    {selectedSku.inventoryStatus === 'Active' ? (
                      <ProductCta
                        selectedSku={selectedSku}
                        quantity={1}
                        onAddToCart={onModalClose}
                        buttonStyles={'w-[135px] tracking-[1px] min-h-[40px]'}
                      />
                    ) : (
                      <div className="h-8 mb-[35px] text-red-500">
                        <InventoryStatusMessage
                          status={selectedSku.inventoryStatus}
                        />
                      </div>
                    )}
                  </div>
                  {/* END: experimental hidden feature */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
