export interface IYoutubeFrameProps {
  source: string | null;
  className?: string;
  width: string;
  height: string;
  autoplay?: boolean;
  lazy?: boolean;
}

export const YouTubeFrame = ({
  source,
  className = '',
  width,
  height,
  autoplay = false,
  lazy = false
}: IYoutubeFrameProps) =>
  source ? (
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${source}${
        autoplay ? '?autoplay=1' : ''
      }`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      loading={lazy ? 'lazy' : undefined}
      className={className}
    />
  ) : null;
